import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ThemeProvider } from 'styled-components';

import { Box, Button, Show } from '@core';
import { Animation, ImageCore, LinkWrapper, Text } from '@components';
import { dealsTheme } from '@theme';
import { FadeInTitle, TitleWithIncrementalDiscount } from '@shared/deals-helpers';
import { formatLink } from '@shared/format-helpers';
import { usePlansValuesContext } from '@context';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

import christmasMobile from '@images/blog-banner/christmas/christmas-mobile.png';
import christmasDesktop from '@images/blog-banner/christmas/christmas-desktop.png';

const ImageBlock = () => (
  <Box width="100%" height="100%">
    <Show.TabletSmall>
      <Box display="flex" justifyContent={{ _: 'center', sm: 'flex-end' }}>
        <ImageCore src={christmasMobile} width={328} alt="Atlas VPN banner" />
      </Box>
    </Show.TabletSmall>
    <Show.TabletLargeAndUp>
      <Box display="flex" justifyContent="flex-end">
        <ImageCore src={christmasDesktop} width={352} alt="Atlas VPN banner" />
      </Box>
    </Show.TabletLargeAndUp>
  </Box>
);

export const BannerComponent = ({
  t,
  isLoading,
  currency,
  discountPercentage,
  extraTime,
  pricePerMonth,
  subscriptionPeriod,
}) => {
  const bannerRef = useRef(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    if (!isIntersecting) {
      const observer = new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry.isIntersecting)
      );
      observer.observe(bannerRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isIntersecting]);

  useEffect(() => {
    if (isIntersecting) {
      trackCustomGA4Event({ eventName: GA4_EVENTS.bannerView });
    }
  }, [isIntersecting]);

  return (
    <ThemeProvider theme={dealsTheme}>
      <div ref={bannerRef}>
        <Box
          backgroundColor="bannerBackground"
          display="flex"
          justifyContent="space-between"
          flexWrap={{ _: 'wrap', sm: 'nowrap' }}
          overflow="hidden"
          borderRadius="4px"
        >
          <Box py={{ _: 24, sm: 32 }} px={24} flexBasis={280} flexGrow={10} flexShrink={0}>
            <Text.Subheading2 fontSize={20} lineHeight="25px" fontFamily="title">
              {discountPercentage ? (
                <TitleWithIncrementalDiscount
                  finalDiscountPercentage={!isLoading && discountPercentage}
                  i18nKey="campaigns:christmas.title"
                  components={[
                    <FadeInTitle
                      as="span"
                      color="danger"
                      fontFamily="inherit"
                      whiteSpace="nowrap"
                      key={0}
                      fontSize={20}
                      lineHeight="25px"
                    >
                      <Box display="inline-flex" justifyContent="center" minWidth={50} />
                    </FadeInTitle>,
                  ]}
                />
              ) : (
                t('deals:header.getDeal')
              )}
            </Text.Subheading2>
            <Text.Body3 my={16} minHeight={20}>
              {!isLoading && (
                <Animation.FadeIn as="span">
                  <Trans
                    i18nKey="deals:header.description"
                    values={{
                      planDetail: t(`deals:header.planDeal.${subscriptionPeriod}`, {
                        currency,
                        pricePerMonth,
                      }),
                      extraComment: extraTime && t(`plans:monthsExtra.${extraTime}`),
                    }}
                    components={[<Text.Body3 as="span" color="danger" key={0} />]}
                  />
                </Animation.FadeIn>
              )}
            </Text.Body3>
            <LinkWrapper link={formatLink(t('navigation:paths.get'))}>
              <Box maxWidth={200}>
                <Button
                  type="button"
                  fullWidth
                  onClick={() => trackCustomGA4Event({ eventName: GA4_EVENTS.bannerClick })}
                >
                  {t('deals:common.grabDeal')}
                </Button>
              </Box>
            </LinkWrapper>
          </Box>
          <Box flexGrow={{ _: 1, lg: 0 }} flexShrink={0} alignSelf={{ sm: 'flex-end' }}>
            <ImageBlock />
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
};

const ChristmasBlogBanner = () => {
  const { t } = useTranslation();
  const { isLoading, suggestedPlan } = usePlansValuesContext();

  return (
    <BannerComponent
      t={t}
      isLoading={isLoading}
      currency={suggestedPlan?.currency}
      discountPercentage={suggestedPlan?.discountPercentage}
      extraTime={suggestedPlan?.extraTime}
      pricePerMonth={suggestedPlan?.pricePerMonth}
      subscriptionPeriod={suggestedPlan?.subscriptionPeriod}
    />
  );
};

export default ChristmasBlogBanner;
